import { createApp } from 'vue'
import Vuex from 'vuex'
import users from './user';


createApp().use(Vuex);
export default new Vuex.Store({
    modules: {
        ...users

    }
})
