import request from '../utils/request';
import store from '@/store';

// 轮播图

export const systemBanner = (params) => request({type: 'get', url: '/api/system/banner', data: params})


// 产品列表

export const productList = (params) => request({type: 'get', url: '/api/product/list', data: params})
// 公司动态

export const newsList = (params) => request({
    type: 'get', url: '/api/news/list', data: {
        ...params,
        isEng: store.getters.language === 2 ? 1 : ''
    }
})
// 系统信息

export const systemSystemContent = (params) => request({
    type: 'get', url: '/api/system/system-content', data: {
        ...params,
        isEng: store.getters.language === 2 ? 1 : ''
    }
})
// 其他信息

export const systemSetting = (params) => request({
    type: 'get', url: '/api/system/setting', data: {
        ...params,
        isEng: store.getters.language === 2 ? 1 : ''
    }
})

// 导航

export const indexNavi = (params) => request({type: 'get', url: '/api/index/navi', data: params})


//研发管线表格
export const systemGetPipeline = (params) => request({type: 'get', url: '/api/system/get-pipeline', data: params})

//推荐新闻
export const newsGetRecNews = (params) => request({type: 'get', url: '/api/news/get-rec-news', data: {
        ...params,
        isEng: store.getters.language === 2 ? 1 : ''
    }
})
//创新药那一栏
export const productRec = (params) => request({type: 'get', url: '/api/product/rec', data: {
        ...params,
        isEng: store.getters.language === 2 ? 1 : ''
    }
})

// 产品品牌
export const productBrand = (params) => request({type: 'get', url: '/api/product/brand', data: params})


// 产品品牌分类
export const productCate = (params) => request({type: 'get', url: '/api/product/cate', data: params})


//新闻详情
export const newsGetNews = (params) => request({type: 'get', url: '/api/news/get-news', data: params})


//发送留言
export const leaveMessage = (params) => request({type: 'post', url: '/api/system/leave-message', data: params})

// 登录接口
export const LoginApi = (params) => request({type: 'post', url: '/freemdc/auth/logins', data: params})





