import {LoginApi} from '@/request/api';
import store from '@/store';

const loginModules = {
    state: {},
    getters: {},
    mutations: {},
    actions: {
        /*手机号登录*/
        phoneLogin(context, {mobileNumber, password, userType, inviterId}) {
            console.log(mobileNumber, password, userType, inviterId, '污污污污污')
            return new Promise((resolve, reject) => {
                LoginApi({
                    'mobileNumber': mobileNumber,
                    'password': password,
                    'userType': userType,
                    'inviterId': inviterId
                }).then(res => {
                    console.log(res)
                    if (res.code == 0) {
                        context.commit('updateToken', res.data.token);
                        store.dispatch('getUser');
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }, (err) => {
                    reject(err)
                })
            })
        }

    }
}
export default loginModules