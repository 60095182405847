<template>

  <div v-if="isMobile" >
    <div class="flex align-items-center flex-wrap-wrap w-100 container justify-content-space-between pt-10 pb-10 " v-if="qrcode_list">
      <div class="w-50" v-for="(item,index) in qrcode_list" :key="index">
        <div class="pr-10 pl-10 flex flex-direction-column align-items-center  ">
          <img :src="item.fileList[0].url" alt="" class="w-100 display-block" >
          <text class="mt-3 line-height1_3em f-6">{{ item.name }}</text>
        </div>
      </div>

    </div>
    <div class="area_45 foot-line pad-10 flex align-items-flex-start flex-direction-column background-white w-100 box-sizing-border-box">
      <div class="pad-3">
        <text class="font-weight-bold">{{ $store.getters.language === 1 ? '联系' : 'Contact' }}</text>
      </div>
      <div class="pad-3 flex align-items-flex-end">
        <text class="">{{ $store.getters.language === 1 ? '服务热线' : 'Service Hotline' }}：</text>
        <text class="font-weight-bold">{{ settings.service_tel }}</text>
      </div>
      <div class="pad-3 flex align-items-flex-end">
        <text class="">{{ $store.getters.language === 1 ? '不良反应投诉邮箱' : 'Adverse Reaction Complaint Email' }}：</text>
        <text class="font-weight-bold">{{ settings.email }}</text>
      </div>
      <div class="pad-3 flex align-items-flex-end">
        <text class="">{{ $store.getters.language === 1 ? '公司地址' : 'Company Address' }}：</text>
        <text class="font-weight-bold">{{ settings.address }}</text>
      </div>
    </div>

  </div>

  <!--  二维码  -->
  <div class="flex pt-25 pb-25 background-white justify-content-center footbox"  v-else>
    <div class="area_90 flex">
      <div class="area_10 foot-line pad-10">
        <img :src="settings.logo" class="area_75"/>
      </div>
      <div class="area_45 foot-line pad-10 flex align-items-flex-start flex-direction-column f-7">
        <div class="pad-3">
          <text class="font-weight-bold">{{ $store.getters.language === 1 ? '联系' : 'Contact' }}</text>
        </div>
        <div class="pad-3 flex align-items-flex-end">
          <text class="">{{ $store.getters.language === 1 ? '服务热线' : 'Service Hotline' }}：</text>
          <text class="font-weight-bold">{{ settings.service_tel }}</text>
        </div>
        <div class="pad-3 flex align-items-flex-end">
          <text class="">{{ $store.getters.language === 1 ? '不良反应投诉邮箱' : 'Adverse Reaction Complaint Email' }}：</text>
          <text class="font-weight-bold">{{ settings.email }}</text>
        </div>
        <div class="pad-3 flex align-items-flex-end">
          <text class="">{{ $store.getters.language === 1 ? '公司地址' : 'Company Address' }}：</text>
          <text class="font-weight-bold">{{  $store.getters.language === 1 ? settings.address : settings.address_eng}}</text>
        </div>
      </div>

      <div class="pad-10 area_45">
        <div class="pl-3 box-sizing-border-box pad-3">
          <text class="font-weight-bold">{{ $store.getters.language === 1 ? "扫码关注" : "Scan to Follow" }}</text>
        </div>
        <div class="pl-3 box-sizing-border-box flex align-items-flex-start mt-5" v-if="qrcode_list">
          <div class="flex flex-direction-column align-items-center pr-10 w-25" v-for="(item,index) in qrcode_list" :key="index">
            <img :src="item.fileList[0].url" alt="" class="w-100 display-block"  >
            <text class="mt-3 line-height1_3em f-6">{{ $store.getters.language === 1 ? item.name : item.eng_name}}</text>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!--  网站备案  -->
  <div class="pt-25 pb-25 bg04357c f-color-white flex  align-items-flex-start mo-flex-column" >
    <div class="container flex align-items-center mo-flex-column ">
      <div class="area_70 justify-content-center text-align-center flex align-items-flex-start mo-flex-wrap">
        <text class="f-6">{{ $store.getters.language === 1 ? settings.icp_no : settings.icp_no_eng }}</text>
      </div>

      <div class="  mo_paddingtop30 flex align-items-center flex-wrap-nowrap " style="height: 20px;">
        <img src="@/static/icon/index/records.png" alt="" class="h-100 "/>
        <text class="f-6 " style="white-space: nowrap;">{{ settings.security_no }} </text>
      </div>
    </div>
  </div>

  <div class="to-top cursor-pointer" v-if="showButton" @click="scrollToTop">
    <text class="hrd-icon icon-zhiding h-100 w-100 f-16 opacity_06"></text>

  </div>

</template>

<script>
import {systemSetting} from "@/request/api";
import {mapGetters} from "vuex";

export default {
  name: "yht-footer",
  data(){
    return{
      settings:{},
      qrcode_list:[],
      showButton: false
    }
  },
  computed:{
    ...mapGetters(['language']),
    isMobile() {
      return this.$store.getters.screenWidth <= 800;
    }
  },
  created() {
    this.init() //网站底部信息

  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.checkScroll);
  },
  beforeUnmount() {
    // 移除滚动事件监听
    window.removeEventListener("scroll", this.checkScroll);
  },
  watch: {
    language(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init(); // 这里调用你的 API 请求函数
      }
    }
  },

  methods:{
    init(){
      this.getSystemSetting()
    },
    getSystemSetting(){
      let storedInformation = JSON.parse(localStorage.getItem('information'));

      if (storedInformation === null) {
        systemSetting().then(res => {
          if (res.code === 0) {
            this.settings = res.data
            this.qrcode_list = JSON.parse(res.data.qrcode_list)
            localStorage.setItem('information', JSON.stringify(this.settings));
          }
        })
      }else{
        // this.settings = storedInformation
        // this.qrcode_list = JSON.parse(storedInformation.qrcode_list)

        //改用里，不存缓存了begin
        systemSetting().then(res => {
          if (res.code === 0) {
            this.settings = res.data
            this.qrcode_list = JSON.parse(res.data.qrcode_list)
            localStorage.setItem('information', JSON.stringify(this.settings));
          }
        })
        //改用里，不存缓存了end
      }

    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkScroll() {
      // 获取滚动距离，这里设置为 100，你可以根据需要修改
      if (window.scrollY > 100) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.foot-line {
  position: relative;
  height: 100%;
}

.foot-line::before {
  content: "";
  display: block;
  width: 1px;
  height: 180px;
  background: #d0dbea;
  position: absolute;
  right: 0;
  top: 20px;
}
.footbox {
  border-top: 1px solid #d0dbea;
}
.to-top{
  height:40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: #6e6e6e;
  .opacity_06{
    opacity: 0.6;
  }


}
@media ( max-width: 800px ) {
  .to-top{
    bottom: 10px;
    right: 10px;
  }
}

</style>