<template>
  <div class="index pos-r iconfont w-100">

    <yht-navbar :current="current" :isIndex="true" @changeCurrent="changeCurrent"></yht-navbar>

    <div class="display-block swiper-container w-100 box-sizing-border-box">


      <swiper
          class="w-100 pos-r"
          :loop="true"
          :autoplay="{ delay: 2000, disableOnInteraction: false }"
          :pagination="{ type: 'custom'}"
          @slideChange="handleSlideChange" :style="isMobile ? {height: '100vh'} : {height : '450px'}">
        <swiper-slide v-for="(item,index ) in wallpaper" :key="index" class="w-100 overflow-hidden pos-r">
          <div class="h-100 w-100 pos-r">
            <el-image :src="isMobile ? item.banner_pic : item.banner_pic_mobile"
                      class="w-100 overflow-hidden h-100 wall-item" @mouseenter="zoomIn" @mouseleave="zoomOut"
                      :class="{ 'mouseIn': scale, 'mouseOut': !scale}" fit="cover" ></el-image>
            <div :class="{'poabsolute-w50' : isMobile}"
                 class="fade-in flex justify-content-flex-start flex-direction-column banner-towing container pos-a box-sizing-border-box "
                 :style="isMobile ? { left: bannerTowing[index].left, top: bannerTowing[index].top } : {left : '50%', top : '20%'}">
              <text :class="$store.getters.language === 1 ? 'line-height1_5em' : 'line-height1_1em'" class=" mo_tcenter cn width_30 mo_width100" v-html="$store.getters.language === 1 ? item.banner_text : item.banner_pic_text_eng"></text>

              <text class="en mo_tcenter pt-10" style="white-space: nowrap" v-html="item.banner_pic_text_eng" v-if="$store.getters.language === 1"></text>

            </div>
          </div>
        </swiper-slide>


      </swiper>

    </div>

    <div class="pt-30 container mo_paddingtop20 ml-0 mr-0 " data-aos="fade-up">
      <div class="flex align-items-center pb-20">
        <text class="title-box blue-color font-weight-bold">{{ $store.getters.language === 1 ? '新闻' : 'news' }}</text>
        <text class="title-box">{{$store.getters.language === 1 ? '动态' : 'dynamic'}}</text>
        <div class="pl-10 pr-10 pb-10 pt-10 flex align-items-center cursor-pointer line-height-1" @click="to('mediaIndex', {}, $router)">
          <text class="f-7">{{$store.getters.language === 1 ? '了解更多' : 'more'}} >></text>
        </div>
      </div>
    </div>

    <div class="news-box container" data-aos="fade-up" v-if="isMobile">


      <swiper :coverflowEffect="{
              rotate: 30,
              stretch: 10,
              depth: 60,
              modifier: 2,
              slideShadows : true
              }" effect="coverflow" :centeredSlides="true" :slidesPerView="3"
              :pagination="{  clickable: true }" @slideChange="newsHandleCarouselChange"
              :style="isMobile ? {height: '384px'} : ''">
        <swiper-slide v-for="(item,index) in newsList" :key="index">
          <div class="news flex flex-direction-column align-items-center w-100 box-sizing-border-box">
            <img class="photo h-100 w-100" :src="item.cover_image"
                 fix="cover" v-if="item.cover_image"/>
            <img class="photo h-100 w-100" src="http://image.hnyykj.com.cn/img_16968333580775.png"
                 fix="cover" v-else/>
            <div class="pl-5 pr-5 pb-5 pt-5">
              <text class="f-6 pb-7">{{ item.news_type }}</text>
              <text class="f-13 line-2 h4">{{ item.title }}
              </text>
            </div>

          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div class="news-box container" data-aos="fade-up" v-else>
      <swiper :pagination="{  clickable: true }" @slideChange="newsHandleCarouselChange"
              :style="isMobile ? {height: '384px'} : ''">
        <swiper-slide v-for="(item,index) in newsList" :key="index">
          <div class="news flex flex-direction-column align-items-center w-100 box-sizing-border-box">
            <el-image class="photo h-100 w-100" :src="item.cover_image"
                      fix="cover" v-if="item.cover_image"></el-image>
            <el-image class="photo h-100 w-100" src="http://image.hnyykj.com.cn/img_16968333580775.png"
                      fix="cover" v-else></el-image>
            <div class="pl-5 pr-5 pb-5 pt-5">
              <text class="f-6 pb-7">{{ item.news_type }}</text>
              <text class="f-13 line-2 h4">{{ item.title }}
              </text>
            </div>

          </div>
        </swiper-slide>
      </swiper>
    </div>


    <!--   蓝条begin -->
    <div class="pt-20 w-100 overflow-hidden">
      <div class="bg134290 w-100 pb-15">
        <div class="container flex align-items-center mo-flex-wrap" data-aos="fade-up">
          <div class="flex flex-direction-column align-items-center w-50 mo_padding20 pad-15">
            <img src="../../static/icon/index/icon1.png" class="imgw20"/>
            <text class="f-9 h5 f-color-white mt-5 line-2">{{ companyProfile.length ? companyProfile[0].name : '' }}</text>
            <div class="flex align-items-flex-end pt-7">
              <text class="f-18 h2 font-weight-bold f-color-white  line-height-1" id="countUp1">
                {{ companyProfile.length ? companyProfile[0].content : '' }}
              </text>
              <text class="ml-2 f-color-white f-9 h5 line-height-1">{{ companyProfile.length ? companyProfile[0].unit : '' }}</text>
            </div>
          </div>
          <div class="flex flex-direction-column align-items-center w-50 mo_padding20 pad-15">
            <img src="../../static/icon/index/icon2.png" class="imgw20"/>
            <text class="f-6 f-color-white mt-5 line-2" >{{ companyProfile.length ? companyProfile[1].name : '' }}</text>
            <div class="flex align-items-flex-end pt-7">
              <text class="f-18 h2 font-weight-bold f-color-white  line-height-1" id="countUp2">
                {{ companyProfile.length ? companyProfile[1].content : '' }}
              </text>
              <text class="ml-2 f-color-white f-9 h5 line-height-1">{{ companyProfile.length ? companyProfile[1].unit : '' }}</text>
            </div>
          </div>
          <div class="flex flex-direction-column align-items-center w-50 mo_padding20 pad-15">
            <img src="../../static/icon/index/icon3.png" class="imgw20"/>
            <text class="f-6 f-color-white mt-5 line-2">{{ companyProfile.length ? companyProfile[2].name : '' }}</text>
            <div class="flex align-items-flex-end pt-7">
              <text class="f-18 font-weight-bold f-color-white  h2 line-height-1" id="countUp3">
                {{ companyProfile.length ? companyProfile[2].content : '' }}
              </text>
              <text class="ml-2 f-color-white f-9 h5 line-height-1">{{ companyProfile.length ? companyProfile[2].unit : '' }}</text>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!--   蓝条end -->

    <div class="container" data-aos="fade-up">
      <div class="pt-5 flex align-items-center mo-flex-column">
        <div class="area_60 pad-5 " @click="to(indexMsg[0].link_url,{},$router)">
          <div class="pos-r  waper-height100" v-if="indexMsg.length > 0">
            <el-image :src="indexMsg[0].image[0].url" class="w-100 h-100 "
                      fit="cover" ></el-image>
            <div class="z-index pos-a bottom-0 left-0">
              <div class="border-484c4c ml-15 pb-2 w-fit-content">
                <text class="f-7 p">{{ indexMsg[0].name || '' }}</text>
              </div>
              <div class="mt-10 pt-5 pb-5 pl-15 bg-change ">
                <text class="f-13 font-weight-400 h4">
                  {{ indexMsg[0].content }}
                </text>
              </div>
              <div class="pb-15 pt-15 pl-15">
                <text class="hrd-icon icon-gengduo fa-1_6x h6"></text>
              </div>
            </div>
          </div>
        </div>
        <div class="area_40 flex flex-direction-column align-items-center pad-5">
          <div class="mb-10 w-100">
            <div class="pos-r box-sizing-border-box waper1-height60 w-100" v-if="indexMsg.length > 0" @click="to(indexMsg[1].link_url,{},$router)">
              <img :src="indexMsg[1].image[0].url" class="w-100 h-100 "
                        fit="cover" />
              <div class="z-index pos-a bottom-0 left-0">
                <div class="border-484c4c ml-15 pb-2 w-fit-content">
                  <text class="f-7 p">{{ indexMsg[1].name || '' }}</text>
                </div>
                <div class="mt-10 pt-5 pb-5 pl-15 ">
                  <text class="f-13 font-weight-400 h4">{{ indexMsg[1].content }}</text>
                </div>
                <div class="pb-15 pt-15 pl-15">
                  <text class="hrd-icon icon-gengduo fa-1_6x h6"></text>
                </div>
              </div>
            </div>
          </div>

          <div
              class="zp-lab box-sizing-border-box w-100 flex flex-direction-column align-items-center com-link-032763">
            <div class="pos-r box-sizing-border-box h-100 w-100" v-if="indexMsg.length > 0" @click="to(indexMsg[2].link_url,{},$router)">
              <img :src="indexMsg[2].image[0].url" class="w-100 h-100 "
                        fit="cover" />
              <div class="z-index pos-a bottom-0 left-0">
                <div class="border-484c4c ml-15 pb-2 w-fit-content">
                  <text class="f-7 p">{{ indexMsg[2].name || '' }}</text>
                </div>
                <div class="mt-10 pt-5 pb-5 pl-15 ">
                  <text class="f-13 font-weight-400 h4">{{ indexMsg[2].content }}</text>
                </div>
                <div class="pb-15 pt-15 pl-15">
                  <text class="hrd-icon icon-gengduo fa-1_6x h6"></text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
import {systemBanner, newsList, systemSystemContent, systemSetting} from '@/request/api';
import {useRouter, useRoute, Router} from 'vue-router';


import index, {mapGetters} from 'vuex';
import YhtNavbar from "@/components/template/yht-navbar";

import {CountUp} from 'countup.js';

import {Swiper, SwiperSlide} from 'swiper/vue';

import 'swiper/swiper-bundle.css';


export default {
  name: 'patient-home',
  components: {

    YhtNavbar,
    Swiper,
    SwiperSlide
  },
  data() {
    return {

      activeIndex: 0,
      newsCardCurrentIndex: 0, //卡片的index
      current: 35,
      wallpaper: [{
        banner_pic: "http://image.hnyykj.com.cn/img_16935562986198.jpg",
        banner_pic_mobile: "http://image.hnyykj.com.cn/img_16944249571737.jpg"
      }, {
        banner_pic: "http://image.hnyykj.com.cn/img_16935570304622.jpg",
        banner_pic_mobile: "http://image.hnyykj.com.cn/img_1694424931004.jpg"
      }],
      windowHeight: 0,
      scale: false, // 初始缩放比例为1，即原始大小
      bannerTowing: [{
        cnOne: "科技为本",
        cnTwo: "为人类创造健康生活",
        ENOne: "PROMOTE A HEALTHIER LIFE FOR HUMANKIND THROUGH",
        ENTwo: "ADVANCEMENTS IN SCIENCE.",
        left: '58%',
        top: '40%'
      }, {
        cnOne: "锐意创新",
        cnTwo: "攻坚克难造福患者",
        ENOne: "BENEFIT THE PATIENTS WITH HIGH-QUALITY MEDICATION",
        ENTwo: "AS WE DETERMINED TO INNOVATE.",
        left: '95%',
        top: '40%'
      }],
      startDragX: 0,
      endDragX: 0,
      newsList: [],
      companyProfile: [],  //公司概况
      indexMsg: [],  //首页信息,
      culture:[]  //幻灯片标语
    }

  },
  computed: {
    index() {
      return index
    },
    ...mapGetters(['userInfo','language']),
    isMobile() {
      return this.$store.getters.screenWidth > 800;
    },


  },
  watch: {
    language(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init(); // 这里调用你的 API 请求函数
      }
    }
  },
  created() {
    this.init()
  },
  mounted() {


    // 在组件挂载后获取窗口高度
    this.getWindowHeight();

    // 添加窗口大小变化的事件监听器
    window.addEventListener('resize', this.handleResize);




  },

  methods: {
    to(url, query, r) {
      console.log(url)
      r.push({
        name: url,
        query,
      })
    },

    formatContent(content) {

      return content.replace(/\n/g, "<br>");
    },
    animationListener() {
      let that = this;
      document.addEventListener("animationend", function () {
        if (that.$refs["paginationDot"] && that.$refs["paginationDot"][0]) {
          that.$refs.paginationDot[0].getElementsByClassName(
              "swiper-pagination-bullet-active"
          )[0].children[0].style.display = "none";
        }
      });
    },
    handleSlideChange(swiper) {
      this.activeIndex = swiper.activeIndex

    },


    newsHandleCarouselChange(e) {
      this.newsCardCurrentIndex = e
    },
    changeCurrent(current) {
      this.current = current
    },
    getWindowHeight() {
      // 使用 window.innerHeight 获取当前窗口的高度
      this.windowHeight = window.innerHeight;

    },
    handleResize() {
      // 窗口大小变化时调用此方法来更新窗口高度
      this.getWindowHeight();
    },
    zoomIn() {

      this.scale = true // 鼠标悬停时放大比例
    },
    zoomOut() {
      this.scale = false; // 鼠标移开时还原到原始大小
    },

    //获取banner APi
    getBanner() {
      systemBanner().then(res => {
        if (res.code === 0) {
          this.wallpaper = res.data
          this.wallpaper = this.wallpaper.map((item) => {
            return {
              ...item,
              banner_text: this.formatContent(item.banner_text),
              banner_pic_text_eng: this.formatContent(item.banner_pic_text_eng),
            };
          });
        }


      })
    },

    // 公司动态
    getNewList() {
      newsList({page: 1, pageSize: 10}).then(res => {
        this.newsList = res.data.list
      })
    },
    getSystemSystemContent5() {
      systemSystemContent({contentType: 5}).then(res => {
        if (res.data.records.length) {
          this.indexMsg = JSON.parse(res.data.records);
          console.log(this.indexMsg);


        }
      }).catch(error => {
        console.error('出错了:', error);
      });
    },
    getSystemSystemContent1() {
      systemSystemContent({contentType: 1}).then(res => {
        if(res.data.records.length){
          this.companyProfile = JSON.parse(res.data.records).info
          this.culture = JSON.parse(res.data.records).culture


          /* 文字滚动begin */
          const options = {
            // 如果有其他的配置选项，你可以在这里添加
          };

          const countUp1 = new CountUp('countUp1', this.companyProfile.length ? this.companyProfile[0].content : '' , {
            startVal: 1960,
            separator: '',  // 这里设置为空字符串
            duration: 2
          });
          const countUp2 = new CountUp('countUp2', this.companyProfile.length ? this.companyProfile[1].content : '', {startVal: 0, ...options});
          const countUp3 = new CountUp('countUp3', this.companyProfile.length ? this.companyProfile[2].content : '', {startVal: 0, ...options});

          [countUp1, countUp2, countUp3].forEach(countUp => {
            if (!countUp.error) {
              countUp.start();
            } else {
              console.error(countUp.error);
            }
          });
          /* 文字滚动end */
        }
      }).catch(error => {
        console.error('出错了:', error);
      });
    },

    init() {
      this.getBanner()
      this.getNewList()  //新闻动态
      this.getSystemSystemContent5() //首页信息
      this.getSystemSystemContent1()  //公司概况

    }


  },
  beforeUnmount() {
    // 在组件销毁前移除窗口大小变化的事件监听器，以防内存泄漏
    window.removeEventListener('resize', this.handleResize);

  }

}
</script>

<style lang="scss" scoped>
.index {
  .wall-item {
    transition: transform 4s ease; /* 使用过渡来平滑改变图片大小 */
    overflow: hidden; /* 保证图片不溢出容器 */
  }

  .mouseIn {
    transform: scale(1.1);
    transition: transform 8s ease; /* 添加过渡效果，你可以根据需要调整持续时间 */
  }


  .mouseOut {
    transform: scale(1);
    transition: transform 8s ease; /* 添加过渡效果，你可以根据需要调整持续时间 */
  }

  .line-height1_5em {
    line-height: 1.5em;
  }
  .line-height1_1em{
    line-height: 1.1em;
  }

  .banner-towing.fade-in {
    animation: slideUpFadeIn 1s ease-in-out forwards; /* 动画名称、持续时间、缓动函数、保持最终状态 */
  }

  .fade-in {
    animation: slideUpFadeIn 1s ease-in-out forwards; /* 动画名称、持续时间、缓动函数、保持最终状态 */
  }

  @keyframes slideUpFadeIn {
    0% {
      transform: translateX(-50%) translateY(50px); /* 初始位置：向下偏移50px */
      opacity: 0; /* 初始透明度为0 */
    }
    100% {
      transform: translateX(-50%) translateY(0); /* 最终位置：恢复原始位置 */
      opacity: 1; /* 最终透明度为1，完全显示 */
    }
  }

  .banner-towing {

    color: rgb(19, 66, 144);
    box-sizing: border-box;

    .cn {
      font-size: 40px;
      font-weight: 400;
      letter-spacing: 5px;
    }

    .en {
      font-size: 14px;
    }

    @media (max-width: 800px) {
      .cn {
        font-size: 21px;
        font-weight: 400;
        letter-spacing: 5px;
      }

      .en {
        font-size: 10px;
      }
    }
  }

  .poabsolute-w50 {
    position: absolute;
    left: 50%;

  }

  .title-box {
    font-size: 2.4rem
  }

  @media (max-width: 800px) {
    .title-box {
      font-size: 2.4rem
    }
  }

  .blue-color {
    color: rgb(19, 66, 144);;
  }

  .bg134290 {
    background-color: #134290;
  }



  .waper-height100 {
    height: 600px;
  }

  .waper1-height60 {
    height: 331px;
  }

  .zp-lab {
    height: 248px;
  }

  .com-link-032763:hover {
    background: #032763;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
  }


}


.news-carousel-item {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  left: 10%;
  width: 30%;
}

.swiper-container {
  width: 100%;
  overflow: hidden;

  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #00ff33; /* 两种都可以 */

}

.swiper-slide {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
  margin: 0 24px;

  svg {
    position: absolute;
    display: none;
    left: -7px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  svg {
    display: block;
    animation: circleLoad 9.5s linear;
  }
}

// 旋转动画
@keyframes circleLoad {
  0% {
    stroke-dashoffset: 62;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.news-box {
  .swiper-slide {
    transition: transform 300ms ease-in-out;
  }

  .swiper-slide-prev {
    transform: scale(0.8);
  }

  .swiper-slide-active {
    transform: scale(1);
  }

  .swiper-slide-next {
    transform: scale(0.8);
  }

}
.photo-box{
  width: 450px;
  height: 252px;
  background-color: #c2c2c2;
}
.width_30{
  width: 30% ;
}
</style>