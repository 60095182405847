// import {getUser} from '@/pages/index/request/api';
import {systemSetting} from "@/request/api";

const userModules = {
    state: {
        userInfo: {}, // 个人信息
        token: '',
        userProfile: {},
        blacklist: [], // 黑名单列表
        language: 1,  //1表示中文 2表示en
        menuTitleData:{} //导航栏数组，用于跳转
    },
    getters: {
        userInfo: state => state.userInfo,
        token: state => state.token,
        userProfile: state => state.userProfile,
        language: state => state.language,
        menuTitleData:state=> state.menuTitleData
    },
    mutations: {
        upMenuTitle(state,menuTitleData){
            state.menuTitleData = menuTitleData

            console.log('menuTitle',menuTitleData)
        },

        updateLanguage(state, language) {
            state.language = language;
            console.log(state.language)
        },


        // 更新用户消息获 取个人资料 utils/IMMonitor.js -> readyStateUpdate方法
        updateUserInfo(state, info) {
            state.userInfo = info;
        },
        // 更新用户token
        updateToken(state, token) {
            state.token = token;
        },
        updateUserProfile(state, userProfile) {
            state.userProfile = userProfile
        },
        // 获取我的黑名单列表 utils/IMMonitor.js -> readyStateUpdate方法
        setBlacklist(state, blacklist) {
            state.blacklist = blacklist
        },
        // 黑名单 收到黑名单列表更新通知 utils/IMMonitor.js -> blackListUpdate方法
        updateBlacklist(state, blacklist) {
            state.blacklist.push(blacklist)
        },
        resetUser(state) {
            state.blacklist = []
            state.userProfile = {}
            state.userInfo = {}
            // context.dispatch('logOutReset');
        },


    },
    actions: {
        upMenuTitleAction(context, option){
          context.commit('upMenuTitle',option)
        },
        authAction(context, option) {
            context.commit('updateUserInfo', option.user)
            context.commit('updateToken', option.token)
        },
        // getUser(context, type) {
        //     return getUser().then((res) => {
        //         // if (res.code == 4001) {
        //         // 	return route.toLogin();
        //         // }
        //         if (res.code == 0) {
        //             context.commit('updateUserInfo', {
        //                 ...context.state.userInfo,
        //                 ...res.data.info
        //             });
        //
        //         }
        //     })
        // }

    }
}

export default userModules
