
const screenModules = {
    state() {
        return {
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
        };
    },
    getters: {
        screenWidth: state => state.screenWidth,
        screenHeight: state => state.screenHeight
    },
    mutations: {
        SET_SCREEN_WIDTH(state, width) {
            state.screenWidth = width;
        },
        SET_SCREEN_HEIGHT(state, height) {
            state.screenHeight = height;
        },
    },
}
export default screenModules