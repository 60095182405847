<template>

  <div
      class="w-100 box-sizing-border-box pos-sticky left-0 top-0 z-index-max mean-bar flex align-items-center justify-content-space-between pl-10 pr-10"
      v-if="$store.getters.screenWidth < 800">
    <div class="mo-logo-box cursor-pointer">
      <img :src="logo" class="logo-img h-100" @click="to('index', {}, $router)">
    </div>
    <div class="flex align-items-center">
      <div class="flex align-items-center justify-content-center language cursor-pointer">
        <div :class="{'sel':$store.getters.language === 2}" class="language-button mr-2 cursor-pointer"
             @click="onlanguagechange(2)">
          <text>EN</text>
        </div>
        <div :class="{'sel':$store.getters.language === 1}" class="language-button cursor-pointer"
             @click="onlanguagechange(1)">
          <text>中</text>
        </div>
      </div>

      <div class="meanmenu-reveal flex flex-direction-column justify-content-center" v-if="!mobileMenusShow"
           @click="mobileMenusShowFn">
        <span></span><span class="mt-3"></span><span class="mt-3"></span>
      </div>
      <text class="hrd-icon icon-closel f-12 ml-10 color1e55a8" v-else @click="mobileMenusShowFn"></text>
    </div>
    <div class="pos-f nav left-0 flex flex-direction-column align-items-center box-sizing-border-box w-100 "
         id="nav">
      <div v-for="(item,index) in menuItems" :key="index" class="w-100  ">
        <div class="nav-item flex align-items-center justify-content-space-between w-100 box-sizing-border-box w-100"
             @click="to(item.navigation_url, {live_id: live_id}, $router)">
          <text class="f-5 f-color-white">{{
              $store.getters.language === 1 ? item.navigation_name : item.eng_name
            }}
          </text>
          <text class="hrd-icon icon-tianjia h6 f-color-white" v-if="item.subMenu"
                @click.stop="showMenuChildAdd(item.navigation_id)"></text>
        </div>
        <div v-show="showMenuChildArray.includes(item.navigation_id)">
          <div class="nav-item-child flex align-items-center w-100 box-sizing-border-box"
               v-for="subMenuItem in item.subMenu"
               :key="subMenuItem"
               @click="to(subMenuItem.navigation_url, {href:subMenuItem.navigation_url_anchor}, $router,{cn :subMenuItem.navigation_name, en : subMenuItem.eng_name}, {cn: item.navigation_name , en: item.eng_name}, item.navigation_url)">
            <text class="f-5">{{
                $store.getters.language === 1 ? subMenuItem.navigation_name : subMenuItem.eng_name
              }}
            </text>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="w-100 box-sizing-border-box pos-f left-0 z-index-max navbar-index navbar" id="navbar" v-else>
    <div class="navbox box-sizing-border-box pos-r h-100 w-100">
      <div
          class="pos-a bottom-0 left-0 h-100 w-100 box-sizing-border-box flex align-items-center justify-content-space-between"
          style="height: 70px">
        <div class="logo-box flex align-items-center justify-content-center pos-r cursor-pointer">
          <img :src="logo" class="logo-img h-100" @click="to('index', {}, $router)">
        </div>
        <div class="flex align-items-center h-100">
          <div class="nav fright flex align-items-center h-100">
            <ul class="cf flex align-items-center h-100">

              <li class="menuLi" v-for="menuItem in menuItems" :key="menuItem.navigation_id"
                  @mouseenter="showMenu(menuItem.navigation_id)"
                  @mouseleave="hideMenu(menuItem.navigation_id)"
                  @click="to(menuItem.navigation_url, {live_id: live_id}, $router)">
                <text class="mainmenu"
                      :class="{ 'hovered': activeMenu === menuItem.navigation_id,'font-weight-bold hovered' : current  == menuItem.navigation_id  }">
                  {{ $store.getters.language === 1 ? menuItem.navigation_name : menuItem.eng_name }}
                </text>
                <div class="menuListBox flex flex-direction-column align-items-center justify-content-center"
                     v-if="activeMenu === menuItem.navigation_id">
                  <text v-for="subMenuItem in menuItem.subMenu" :key="subMenuItem.navigation_id"
                        @click.stop="to( subMenuItem.navigation_url, {href:subMenuItem.navigation_url_anchor}, $router,{cn :subMenuItem.navigation_name, en : subMenuItem.eng_name}, {cn: menuItem.navigation_name , en: menuItem.eng_name}, menuItem.navigation_url) ">
                    {{ $store.getters.language === 1 ? subMenuItem.navigation_name : subMenuItem.eng_name }}
                  </text>

                </div>
              </li>


            </ul>
          </div>
          <div class="flex align-items-center justify-content-center language">
            <div :class="{'sel':$store.getters.language === 2}" class="language-button mr-2 cursor-pointer"
                 @click="onlanguagechange(2)">
              <text>EN</text>
            </div>
            <div :class="{'sel':$store.getters.language === 1}" class="language-button cursor-pointer"
                 @click="onlanguagechange(1)">
              <text>中</text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {mapMutations} from 'vuex';
import {indexNavi, systemSetting} from "@/request/api";

export default {
  name: "yht-navbar",
  computed: {
    ...mapGetters(['userInfo'])
  },
  data() {
    return {
      activeMenu: null,
      menuItems: [
        {
          id: 0,
          label: '首页',
          url: 'index'
        }, {
          id: 1,
          label: '关于和达瑞',
          url: 'aboutUSIndex',
          subMenu: [
            {label: '公司概况', url: 'aboutUsSurvey'},
            {label: '公司战略', url: 'aboutUsStrategy'},
            {label: '领导关怀', url: 'leaderCare'},
            {label: '公司荣誉', url: 'honor'},
            {label: '合规发展', url: 'development'},
            {label: '社会公益', url: 'socialWelfare'},
            {label: '公示公告', url: 'notice'},
          ]
        }, {
          id: 2,
          label: '新闻媒体',
          url: 'mediaIndex',
          subMenu: [
            {label: '新闻速递', url: 'NewsTypeOne'},
            {label: '焦点新闻', url: 'NewsTypeTwo'},
            {label: '公益新闻', url: 'NewsTypeThree'}
          ]
        },
        {
          id: 3,
          label: '研发创新',
          url: 'RDIndex',
          subMenu: [
            {label: '创新体系', url: 'RDInnovation'},
            {label: '创新投入', url: 'RDResources'},
            {label: '研发管线', url: 'RDPipeline'}
          ]
        },
        {
          id: 4,
          label: '我们的产品',
          url: 'productIndex',
          subMenu: [
            {label: '创新药', url: 'productIndex', href: '#pinv0'},
            {label: '抗肿瘤药', url: 'productIndex', href: '#pinv1'},
            {label: '手术用药', url: 'productIndex', href: '#pinv2'},
            {label: '造价及介入药品', url: 'productIndex', href: '#pinv3'},
            {label: '综合药及其他', url: 'productIndex', href: '#pinv4'}
          ]
        },
        {
          id: 5,
          label: '投资者关系',
          url: 'investorIndex',
          subMenu: [
            {label: '临时公告', url: 'provisionalNotice'},
            {label: '定期公告', url: 'regularAnnouncement'},
            {label: '公司治理', url: 'governance'},
            {label: '环境社会及管治', url: 'esg'},
            {label: '投资者联系', url: 'InvestorContact'}
          ]
        },
        {
          id: 6,
          label: '职业发展',
          subMenu: [
            {label: '人才发展', url: 'talentDevelopment'},
            {label: '雇主荣誉', url: 'employerHonor'}
          ]
        },
        {
          id: 7,
          label: '公示公告',
          subMenu: [
            {label: '寻源公告', url: 'purchasing-list1'},
            {label: '公示结果', url: 'purchasing-list2'},
            {label: '招标信息', url: 'purchasing-list3'},
            {label: '政策法规', url: 'purchasing-list4'}
          ]
        }
      ],
      mobileMenus: [
        {
          id: 0,
          url: 'index',
          label: '首页'
        }, {
          id: 1,
          label: '关于和达瑞',
          url: 'aboutUSIndex',
          subMenu: [
            {label: '公司概况', url: 'aboutUsSurvey'},
            {label: '公司战略', url: 'aboutUsStrategy'},
            {label: '领导关怀', url: 'leaderCare'},
            {label: '公司荣誉', url: 'honor'},
            {label: '合规发展', url: 'development'},
            {label: '社会公益', url: 'socialWelfare'},
            {label: '公示公告', url: 'notice'},
          ]
        }, {
          id: 2,
          label: '新闻媒体',
          url: 'mediaIndex',
          subMenu: [
            {label: '新闻速递', url: 'NewsTypeOne'},
            {label: '焦点新闻', url: 'NewsTypeTwo'},
            {label: '公益新闻', url: 'NewsTypeThree'}
          ]
        },
        {
          id: 3,
          label: '研发创新',
          url: 'RDIndex',
          subMenu: [
            {label: '创新体系', url: 'RDInnovation'},
            {label: '创新投入', url: 'RDResources'},
            {label: '研发管线', url: 'RDPipeline'}
          ]
        },
        {
          id: 4,
          label: '我们的产品',
          url: 'productIndex',
          subMenu: [
            {label: '创新药', url: 'productIndex', href: '#pinv0'},
            {label: '抗肿瘤药', url: 'productIndex', href: '#pinv1'},
            {label: '手术用药', url: 'productIndex', href: '#pinv2'},
            {label: '造价及介入药品', url: 'productIndex', href: '#pinv3'},
            {label: '综合药及其他', url: 'productIndex', href: '#pinv4'}
          ]
        },
        {
          id: 5,
          label: '投资者关系',
          url: 'investorIndex',
          subMenu: [
            {label: '临时公告', url: 'provisionalNotice'},
            {label: '定期公告', url: 'regularAnnouncement'},
            {label: '公司治理', url: 'governance'},
            {label: '环境社会及管治', url: 'esg'},
            {label: '投资者联系', url: 'InvestorContact'}
          ]
        },
        {
          id: 6,
          label: '职业发展',
          subMenu: [
            {label: '人才发展', url: 'talentDevelopment'},
            {label: '雇主荣誉', url: 'employerHonor'}
          ]
        },
        {
          id: 7,
          label: '公示公告',
          subMenu: [
            {label: '寻源公告', url: 'purchasing-list1'},
            {label: '公示结果', url: 'purchasing-list2'},
            {label: '招标信息', url: 'purchasing-list3'},
            {label: '政策法规', url: 'purchasing-list4'}
          ]
        }
      ],
      mobileMenusShow: false,  //手机菜单是否显示，
      showMenuChildArray: [], //显示的手机子菜单
      logo: ''
    }
  },
  props: {
    current: {
      type: Number,
      default: 1
    },
    isIndex: {
      type: Boolean,
      default: false
    }

  },
  mounted() {


    if (this.$store.getters.screenWidth <= 800) {  //手机端

    } else {
      const navbar = document.getElementById("navbar");
      if (this.isIndex) {

        navbar.classList.remove("navbar"); // 移除"sticky"类
        navbar.classList.add("navbar-index"); // 添加"sticky"类
        window.addEventListener("scroll", () => {

          if (window.scrollY > 0) {

            navbar.classList.add("sticky"); // 添加"sticky"类

          } else {

            navbar.classList.remove("sticky"); // 移除"sticky"类
          }
        });
      } else {
        navbar.classList.remove("navbar-index"); // 移除"sticky"类
        navbar.classList.add("navbar"); // 添加"sticky"类
      }
    }
    this.getSystemSetting()
    this.getIndexNavi()
  },

  methods: {
    ...mapMutations([
      'updateLanguage', // 映射 this.updateLanguage(commit) 为 this.$store.commit('updateLanguage')
      'upMenuTitle'
    ]),
    getIndexNavi() {  //导航，到时候记得删除

      let nav = JSON.parse(localStorage.getItem('nav'));
      if (nav === null) {
        indexNavi().then(res => {
          if (res.code === 0) {
            this.menuItems = res.data
            localStorage.setItem('nav', JSON.stringify(this.menuItems));
          }
        }).catch(error => {
          console.error('出错了:', error);
        });
      } else {
        // this.menuItems = nav

        //改用里，不存缓存了begin
        indexNavi().then(res => {
          if (res.code === 0) {
            this.menuItems = res.data
            localStorage.setItem('nav', JSON.stringify(this.menuItems));
          }
        }).catch(error => {
          console.error('出错了:', error);
        });
        //改用里，不存缓存了end
      }

    },
    getSystemSetting() {
      let storedInformation = JSON.parse(localStorage.getItem('information'));


      if (storedInformation === null) {
        systemSetting().then(res => {
          if (res.code === 0) {
            this.logo = res.data.logo
            localStorage.setItem('information', JSON.stringify(res.data));
          }
        })
      } else {
        this.logo = storedInformation.logo
      }

    },
    mobileMenusShowFn() {
      this.mobileMenusShow = !this.mobileMenusShow;
      const box = document.getElementById("nav");

      if (box.classList.contains("expanded")) {
        box.classList.remove("expanded");
      } else {
        box.classList.add("expanded");
      }
    },

    /*添加展开的子菜单*/
    showMenuChildAdd(id) {
      if (this.showMenuChildArray.includes(id)) {
        let index = this.showMenuChildArray.indexOf(id);
        if (index !== -1) {
          this.showMenuChildArray.splice(index, 1);
        }
      } else {
        this.showMenuChildArray.push(id)
      }

      console.log(this.showMenuChildArray)
    },
    changeCurrent(current) {
      this.$emit('changeCurrent', current)
    },
    to(url, query, r, subMenuTitle, menuTitle, menuHref) {

      //如果存在href就跳转div
      if(url === '#' || ''){
        return
      }

      if (subMenuTitle) {
        if (menuHref === 'productIndex') {
          setTimeout(() => {
            const targetElement = document.querySelector(this.$route.query.href);

            if (targetElement) {

              targetElement.scrollIntoView({behavior: 'smooth'});


            }
          }, 10)
        }

        const data = {
          menu: {
            cnMenuTitle: menuTitle.cn,
            enMenuTitle: menuTitle.en,
            menuHref: menuHref
          },
          cnSubMenuTitle: subMenuTitle.cn,
          enSubMenuTitle:subMenuTitle.en
        }
        console.log(data)
        localStorage.setItem('menuNav', JSON.stringify(data));

      }


      r.push({
        name: url,
        query,
      })

    },


    showMenu(menuId) {


      this.activeMenu = menuId;

    },
    hideMenu(menuId) {
      this.activeMenu = null;
    },
    onlanguagechange(e) {
      this.updateLanguage(e)

    }
  }
}
</script>

<style lang="scss" scoped>


.menuLi {
  list-style: none; /* 去除圆点 */
  padding-left: 0; /* 去除默认的内边距 */
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 20px;
  cursor: pointer;
}

a {
  color: #000;
  outline-style: none;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
}

.navbox {

  //top: -30px;

  //background: rgba(255, 255, 255, 0);
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.logo-box {
  width: auto;
  height: 100%;
  left: 5%;
  z-index: 10;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;

  .logo-img {
    height: 30px;
  }
}

.language {
  @media (min-width: 800px) {
    width: 288px;
  }

  .language-button {
    background-color: #828282;
    width: 28px;
    text-align: center;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
  }

  .language-button.sel {
    background-color: #1e55a8;
  }
}

.menuListBox {
  width: auto;
  line-height: 38px;
  top: 70px;
  left: 0;
  background: #1e55a8;
  position: absolute;

  text {
    display: block;
    width: 100%;
    padding: 0 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    white-space: nowrap;
  }

}

.hovered {
  color: #1e55a8;
}


.navbar-index {
  position: fixed;
  top: 0;
  height: 100px !important;
  background-color: transparent;
  box-shadow: none;
  color: #134290 !important;
  transition: background-color 0.5s, transform 15s; /* 添加背景色和transform的过渡效果 */
  transform: translateY(0);
}

.navbar-index.sticky {
  background-color: #FFFFFF;
  position: sticky;
  top: -30px; /* 向上偏移30px */
  height: 100px !important;
  transition: background-color 0.5s, top 0.8s; /* 添加背景色和top的过渡效果 */
  box-sizing: content-box; /* 或 border-box */
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateY(0); /* 因为向上偏移了30px，所以要向下偏移30px来保持元素位置不变 */
  color: #222 !important;
}

.navbar {
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  height: 70px !important;
  transition: background-color 0.3s;
  box-sizing: content-box; /* 或 border-box */
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: #222;
}

/*手机端*/
.mean-bar {

  width: 100%;

  background: #fff;
  height: 60px;
  z-index: 999999;


  .mo-logo-box {
    width: auto;
    height: 30px;
  }

  .meanmenu-reveal {
    width: 24px;
    height: 28px;
    line-height: 24px;
    margin-left: 20px;
    cursor: pointer;
    color: #1e55a8;
    text-decoration: none;


    span {
      display: block;
      background: #1e55a8;
      height: 2px;
    }
  }

  .expanded {
    max-height: calc(100vh - 60px) !important;
  }

  .nav {

    width: 100%;
    background: #1e55a8;
    top: 60px;
    overflow-y: scroll;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;

    .nav-item {
      height: 48px;
      padding: 0 5%;
      border-top: 1px solid rgba(255, 255, 255, 0.1);


    }

    .nav-item-child {
      width: 80%;
      padding: 0 10%;
      opacity: .4;
      filter: alpha(opacity=40);
      border-top: 1px solid rgba(255, 255, 255, 0.051);
      text-shadow: none !important;
      visibility: visible;
      height: 48px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      color: #FFFFFF;
    }
  }


}

.logo-img {
  transform: scale(1.2); /* 默认情况下，放大 1.2 倍 */
  transition: transform 0.3s ease; /* 动画效果 */
}

.sticky .logo-img {
  transform: scale(1); /* 如果在 .sticky 下，则恢复到正常大小 */
}
</style>