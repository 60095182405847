<template>
  <div id="app">
    <div class="content-wrapper">
      <router-view></router-view>
    </div>
    <yht-footer></yht-footer>
  </div>
</template>

<script>

import AOS from 'aos';
import 'aos/dist/aos.css'; // 引入 AOS 的 CSS 文件
import "@/assets/styles/iconfont/iconfont.scss";
import YhtFooter from "@/components/template/yht-footer.vue";


export default {
  name: 'App',
  components: {
    YhtFooter

  },
  mounted() {
    AOS.init();

  },

}
</script>

<style lang="scss">
@import "@/assets/styles/style.scss";
@import "@/assets/styles/global.scss";

#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

}



.content-wrapper {
  flex: 1;
}

yht-footer {
  flex-shrink: 0;
}
* {
  margin: 0;
  padding: 0;

}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  background-color: #f4f4f4;
  font-size: 14px;
  line-height: 21px;


}

//::-webkit-scrollbar {
//  width: 0;
//}

body {
  margin-right: calc(-1 * (100vw - 100%));
}

html {

}
*, *:before, *:after {

}
:root {
  font-size: 14px;
}


</style>
