import {createRouter, createWebHashHistory} from "vue-router"
import Index from '../pages/index/index.vue'
import store from "./../store";
const routes = [
    {
        path:"/",
        redirect:'/index', //重定向
    },
    {
        path:"/index",
        name:"index",
        component: Index
    },
    {
        path:"/aboutUs/index",
        name:"aboutUSIndex",
        component:() =>
            import('@/pages/aboutUs/index.vue')
    },
    {
        path:"/aboutUs/survey",  //公司概况
        name:"aboutUsSurvey",
        component:() =>
            import('@/pages/aboutUs/views/survey.vue')
    },
    {
        path:"/aboutUs/strategy",  //公司战略
        name:"aboutUsStrategy",
        component:() =>
            import('@/pages/aboutUs/views/strategy.vue')
    },
    {
        path:"/aboutUs/leaderCare",  //领导关怀
        name:"leaderCare",
        component:() =>
            import('@/pages/aboutUs/views/leaderCare.vue')
    },
    {
        path:"/aboutUs/honor",  //公司荣誉
        name:"honor",
        component:() =>
            import('@/pages/aboutUs/views/honor.vue')
    },
    {
        path:"/aboutUs/development",  //合规发展
        name:"development",
        component:() =>
            import('@/pages/aboutUs/views/development.vue')
    },
    {
        path:"/aboutUs/socialWelfare",  //社会公益
        name:"socialWelfare",
        component:() =>
            import('@/pages/aboutUs/views/socialWelfare.vue')
    },
    {
        path:"/aboutUs/notice",  //社会公益
        name:"notice",
        component:() =>
            import('@/pages/aboutUs/views/notice.vue')
    },

/*研发创新*/
    {
        path:"/RD/index",  //研发创新
        name:"RDIndex",
        component:() =>
            import('@/pages/RD/index.vue')
    },
    {
        path:"/RD/innovation",  //创新体系
        name:"RDInnovation",
        component:() =>
            import('@/pages/RD/views/innovation.vue')
    },
    {
        path:"/RD/resources",  //创新投入
        name:"RDResources",
        component:() =>
            import('@/pages/RD/views/resources.vue')
    },
    {
        path:"/RD/pipeline",  //研发管线

        name:"RDPipeline",
        component:() =>
            import('@/pages/RD/views/pipeline.vue')
    },

    /*新闻与媒体*/
    {
        path:"/media/index",  //新闻与媒体

        name:"mediaIndex",
        component:() =>
            import('@/pages/media/index.vue')
    },

    {
        path:"/media/NewsTypeOne",  //新闻速递

        name:"NewsTypeOne",
        component:() =>
            import('@/pages/media/views/NewsTypeOne.vue')
    },

    {
        path:"/media/NewsTypeTwo",  //焦点新闻

        name:"NewsTypeTwo",
        component:() =>
            import('@/pages/media/views/NewsTypeTwo.vue')
    },
    {
        path:"/media/NewsTypeThree",  //公益新闻

        name:"NewsTypeThree",
        component:() =>
            import('@/pages/media/views/NewsTypeThree.vue')
    },
    {
        path:"/media/detail",  //新闻详情

        name:"newsDetail",
        component:() =>
            import('@/pages/media/views/detail.vue')
    },

//     我们的产品

    {
        path:"/product/index",

        name:"productIndex",
        component:() =>
            import('@/pages/product/index.vue')
    },
    // 创新药
    {

        path:"/product/innovativeMedicine",

        name:"innovativeMedicine",
        component:() =>
            import('@/pages/product/views/innovativeMedicine.vue')

    },

    // 投资者关系
    {
        path:"/investor/index",

        name:"investorIndex",
        component:() =>
            import('@/pages/investor/index.vue')
    },
    //临时公告
    {
        path:"/investor/provisionalNotice",

        name:"provisionalNotice",
        component:() =>
            import('@/pages/investor/views/provisionalNotice.vue')
    },
    //定期公告
    {
        path:"/investor/regularAnnouncement",

        name:"regularAnnouncement",
        component:() =>
            import('@/pages/investor/views/regularAnnouncement.vue')
    },
    //公司治理
    {
        path:"/investor/governance",

        name:"governance",
        component:() =>
            import('@/pages/investor/views/governance.vue')
    },
    //ESG
    {
        path:"/investor/esg",

        name:"esg",
        component:() =>
            import('@/pages/investor/views/esg.vue')
    },
    //InvestorContact
    {
        path:"/investor/InvestorContact",

        name:"InvestorContact",
        component:() =>
            import('@/pages/investor/views/InvestorContact.vue')
    },
    {
        path:"/development/talentDevelopment",

        name:"talentDevelopment",
        component:() =>
            import('@/pages/development/views/talentDevelopment.vue')
    },
    {
        path:"/development/employerHonor",

        name:"employerHonor",
        component:() =>
            import('@/pages/development/views/employerHonor.vue')
    },
    {
        path:"/purchasing/list1",

        name:"purchasing-list1",
        component:() =>
            import('@/pages/purchasing/views/list1.vue')
    },
    {
        path:"/purchasing/list2",

        name:"purchasing-list2",
        component:() =>
            import('@/pages/purchasing/views/list2.vue')
    },
    {
        path:"/purchasing/list3",

        name:"purchasing-list3",
        component:() =>
            import('@/pages/purchasing/views/list3.vue')
    },
    {
        path:"/purchasing/list4",

        name:"purchasing-list4",
        component:() =>
            import('@/pages/purchasing/views/list4.vue')
    }




















];

const router = createRouter({
    history: createWebHashHistory(),
    routes,


    scrollBehavior(to, from, savedPosition) {
        let menuNav = JSON.parse(localStorage.getItem('menuNav'));
        if(menuNav.menu.menuHref !== 'productIndex') {
            // 只有当目标路由存在时才进行滚动
            if (to && to.fullPath) {
                return {top: 0}; // 将页面滚动到顶部
            } else {
                return savedPosition || {x: 0, y: 0}; // 如果目标路由不存在，则保持当前位置或回到顶部
            }
        }
    }

});

export default router;