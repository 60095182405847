import axios from 'axios'
// import md5 from 'js-md5';
// import {HashRouter} from 'react-router-dom' // 借用HashRouter协助路由操作

// import {getToken} from '../utils/storage.js'
import configData from '@/config';

axios.defaults.baseURL = 'https://api.hdr.91tyyy.com/index.php/'


//用户的请求拦截config是请求对象

axios.interceptors.request.use(function (config) {
  /* 拼接sign begin*/
  // let str = ''
  // for( let i in config.data){
  //   str+="&"+i+"="+config.data[i]
  // }
  // str = str.substr(1);

  // let sign = ''
  //
  // sign = md5(md5(configData.salt)+md5(str))
  // config.headers['SIGN'] = sign;
  /* 拼接sign end */

  //添加token到请求头中
  // if(getToken()){
  //   config.headers['token'] = getToken()
  // }


  return config

}, function (error) {
  // 对请求错误做些什么
  // if (error.response.status === 500 && error.response.data.errCode === 1002) {
  //   // 使用HashRouter
  //   const router = new HashRouter();
  //   // 如果是token过期，直接跳到登录页
  //   router.history.push('/login');
  // }
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});

let map = new Map([

  ['get', get],

  ['post', post],

  ['delete', del],

  ['put', put],

])

function request(options) {

  let method = map.get(options.type)

  if (method) {

    return method(options)

  }

  return new Promise((resolve) => {

    resolve(`没有此${options.type}类型方法`)

  })

}

function get(options) {

  return axios.get(options.url, { params: options.data })

}

function del(options) {

  return axios.delete(options.url, { params: options.data })

}

function post(options) {

  return axios.post(options.url, options.data)

}

function put(options) {

  return axios.put(options.url, options.data)

}



export default request